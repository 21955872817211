<template>
    <CharacterEditPage state="Create"/>
</template>
<script>
    import CharacterEditPage from "./CharacterEditPage";
    export default {
        name: "CharacterCreatePage",
        components: {
            CharacterEditPage
        },
        data() {
            return {
            }
        }
    }
</script>