<template>
<v-card>
	<v-card-title class="text-h4">Uh oh!</v-card-title>
	<v-card-text>
		We couldn't find that page! :c
	</v-card-text>
</v-card>
</template>

<script>

export default {

}
</script>